<template>
  <div id="main-header">
    <div class="container d-flex justify-content-between align-items-center">
      <div
        class="logo cursor-pointer"
        href="/trang-chu"
      >
        <b-img
          v-if="dataConfig.logo"
          class="w-100"
          :src="$serverfile+dataConfig.logo"
        />
      </div>

      <nav
        id="navbar"
        class="navbar"
      >
        <ul>
          <li><a href="/trang-chu">TRANG CHỦ</a></li>
          <li><a href="/danh-nghiep">DOANH NGHIỆP</a></li>
          <li><a href="/tai-lieu">VĂN BẢN PHÁP LÝ</a></li>
          <li><a href="/doanh-nghiep-vi-pham">DOANH NGHIỆP VI PHẠM</a></li>
          <li><a href="/tin-tuc">TIN TỨC</a></li>
          <li><a href="/hom-thu-gop-y">GÓP Ý</a></li>

          <li
            class="dropdown"
          >
            <a href="#">
              TRA CỨU THÔNG TIN
              <feather-icon
                icon="ChevronDownIcon"
                size="16"
                class="icon-chevron-down icon-chevron-down"
              />
              <feather-icon
                icon="ChevronUpIcon"
                size="16"
                class="icon-chevron-down icon-chevron-up"
              />
            </a>
            <ul class="dropdown-home">
              <li><a href="/tra-cuu-nguoi-lao-dong">Tra cứu thông tin người lao động</a></li>
              <li><a href="/tra-cuu-giay-phep">Tra cứu thông tin giấy cấp phép lao động</a></li>

              <li>
                <a href="/tra-cu-thiet-bi">Tra cứu danh sách thiết bị được kiểm định kiểm tra an toàn lao động</a>
              </li>
              <li>
                <a href="/tra-cuu-tinh-hinh-nop-bao-cao">Tình hình nộp báo cáo</a>
              </li>
              <li>
                <a href="/tra-cuu-danh-sach-doanh-nghiep-nop-bao-cao-dinh-ky">Công bố danh sách doanh nghiệp nộp báo cáo định kỳ</a>
              </li>
              <li>
                <a href="/tra-cuu-thong-tin-thanh-tra-kiem-tra">Tra cứu thông tin thanh tra kiểm tra doanh nghiệp</a>
              </li>
              <li>
                <a href="/danh-sach-cac-doanh-nghiep-da-tu-cong-bo-du-dieu-kien-huan-luyen-hang-A">Tra cứu thông tin các doanh nghiệp đã tự công bố đủ điều kiện huấn luyện hạng A.</a>
              </li>
            </ul>
          </li>
          <li v-if="!tokenCheck">
            <a href="/dang-nhap"><span><i class="bi bi-box-arrow-in-right mr-1"></i>Đăng nhập</span></a>
          </li>
          <li
            v-else
            class="dropdown"
          >
            <a href="#">
              <img
                :src="userData && userData.avatar ? ($serverfile + userData.avatar) : require('@/assets/images/logo/avt_home.png')"
                class="avt-admin"
              /></a>
            <ul>
              <!-- v-if="userData.roleIdentity !=='EducationDepartment'"Bussiness "DepartmentOfEducation" -->
              <li
                v-if="userData.roleIdentity ==='DepartmentOfEducation'"
              ><a href="/thong-ke-tong-quang">Quản trị viên</a></li>
              <li
                v-else
              ><a :href="`${userData.roleIdentity ==='EducationDepartment'? '/nguoi-dung/thong-tin-don-vi/phong':'/thong-tin-doanh-nghiep'}`">Thông tin đơn vị</a></li>
              <li
                v-if="userData.roleIdentity ==='DepartmentOfEducation'"
              ><a href="/cai-dat">Cài đặt</a></li>
              <li @click="logout">
                <a href="#">Đăng xuất</a>
              </li>
            </ul>
          </li>
        </ul>
        <i class="bi bi-list mobile-nav-toggle"></i>
      </nav>
      <!-- .navbar -->
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'
import store from '../store'

export default {
  components: {
    BLink, BImg,
  },
  data() {
    return {
      tokenCheck: localStorage.getItem('accessToken'),
      roleUser: null,
      userData: this.$userData(),
      config: {},
    }
  },
  computed: {
    ...mapGetters('app', ['dataConfig']),
  },
  created() {
    this.fetchConfig()
  },
  methods: {
    ...mapActions('app', ['fetchConfig']),
    logout() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      localStorage.removeItem('userData')
      localStorage.removeItem('accessToken')
      this.$ability.update(initialAbility)
      this.$router.push({ name: 'home' })
      this.$router.go()
    },

  },
}
</script>

<style lang="scss">

#main-header {
  width: 100%;
  .container {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
    margin-right: auto;
    margin-left: auto;
  }
}

  .icon-chevron-down{
    margin: 5px;
  }
  .icon-chevron-up {
    margin: 5px;
  }

  .dropdown:hover .icon-chevron-down{
    display: none;
  }
.icon-chevron-up{
  display: none;
}
  .dropdown:hover .icon-chevron-up{
    display: block;
  }

@import "@core/scss/vue/libs/vue-good-table.scss";
@import "../assets/vendor/aos/aos.css";
@import "../assets/css/style.css";
@import "../assets/vendor/animate.css/animate.min.css";
@import "../assets/vendor/bootstrap-icons/bootstrap-icons.css";
</style>
