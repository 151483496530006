/* eslint-disable import/no-unresolved */
/* eslint-disable global-require */
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'

export default {
  // Lấy thông tin cài dặt hệ thống
  async getInfoSetting() {
    try {
      const { data } = await axiosApiInstance.get('/Home/configuration')
      return data
    } catch (err) {
      return err
    }
  },
  // Lấy danh sách kiểm định viên

  async getInspectorList(payload) {
    try {
      const { data } = await axiosApiInstance.get('/Home/inspectors', { params: payload })
      return data
    } catch (err) {
      return err
    }
  },

}
