export default {
  // chủ đề tin tức
  NEWS_GET_PAGING: 'Home/get-news-by-topic',
  BUSSINESS_GET_PAGING: 'Home/bussiness',
  BUSSINESS_DETAIL: '/Home/infoBussiness',
  LEGAL_DOCUMENT_GET_PAGING: 'Home/legal-documents',
  BUSSINESS_VIOLAIONS_GET_PAGING: 'Home/bussinessViolations',
  DOCUMENT_LEGAL_DOWNLOAD_FILE: 'LegalDocument/download-file',
  FEEDBACK_CREATE: 'Home/feedback',
  FEEDBACK_GET: 'Home/feedbacks',
  GET_NEWS_BY_ID: 'Home/get-news-by-id/',
  COMBOBOX_GROUP_NEWS: 'Home/get-topic-news',

  // TRA CỨU THÔNG TIN
  WORKER: 'Home/workers',
  WORKOER_LICENSE: 'Home/get-worker-license',
  DEVIES: 'Home/accreditation-equipments',
  COMBOBOX_BUSINESS: 'Home/get-combobox-bussiness',
  REPORT_SEND: 'Home/list-situation-report-send',
  REPORT_NOT_SEND: 'Home/list-situation-report-not-send',
  SUBMIT_REPORT: 'Home/get-paging-amounce-submit-report',
  DOWNLOAD_SUBMIT_REPORT: 'Home/download-file-amounce-submit-report',
  SUPERVISE: 'Home/supervises',
  DOWNLOAD_FILE_SUPERVISE: 'Home/download-file',
  DETAIL_SUPERVISE: 'Home/supervise/',
  DOWNLOAD_FILE_BUSINESS: 'Home/download-supervise-bussiness-file',
  LIST_BUSINESS: 'Home/list-supervise-bussiness',
  LIST_MISSION_INSPECTION: 'Home/list-mission-of-inspection',
  LIST_SELF_DECLARE_TRAINING: 'Home/list-self-declare-training',

  LIST_PROVINCES: 'Home/provinces', // lấy danh sách tỉnh thành phố
  FECTH_DISTRICTS: 'Home/districts/', // lấy danh sách huyện
  FECTH_WARDS: 'Home/wards/', // lấy dnah sách xã
  FILE_SELF_DECLARE_TRAINING: 'Home/file-self-declare-training', // down load file

}
