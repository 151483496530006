<template>
  <section
    v-if="isLoading"
    id="heroEnterprise"
    :style="{'background': `linear-gradient(to right, rgba(30, 67, 86, 0.8), rgba(30, 67, 86, 0.6)), url(${dataConfig.banner ? `${$serverfile + dataConfig.banner}`: getBackground}) center top no-repeat`}"
    class="d-flex justify-cntent-center align-items-center"
  >
    <div
      id="heroCarousel"
      class="container carousel carousel-fade"
    >
      <!-- Slide 1 -->
      <div class="carousel-item active">
        <div class="carousel-container">
          <h2 class="animate__animated animate__fadeInDown">
            {{ titlePage }}
          </h2>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import store from '../store'

export default {
  props: {
    titlePage: {
      type: String,
      default: 'Hãy truyền tiêu đề trang',
    },
  },
  data() {
    return {
      config: {},
      getBackground: '../img/IMG2.png',
    }
  },
  computed: {
    ...mapGetters('app', ['dataConfig', 'isLoading']),
  },
  created() {
    this.getInfoConfig()
  },
  methods: {
    getInfoConfig() {
      this.getBackground = this.dataConfig.banner
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "../assets/vendor/aos/aos.css";
@import "../assets/css/style.css";
@import "../assets/vendor/animate.css/animate.min.css";
@import "../assets/vendor/bootstrap-icons/bootstrap-icons.css";
</style>
